import * as React from "react"
import Img from "gatsby-image";
import { Typography, Divider } from '@material-ui/core'
import ArticleBody from 'components/ArticleBody'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    margin: 0,
    padding: 0
  },
  hr: {
    margin: '2rem 0'
  },
  image: {
    float: 'left',
    height: '145px',
    width: '145px',
    margin: '3px 20px 5px 5px'
  },
  title: {
    fontSize: '1.4rem',
  },
  text: {
    padding: 0,
    margin: 0
  }
}));

function AboutAuthor(props) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Divider className={classes.hr} />
      <Img
        className={classes.image}
        fluid={props.image}
        alt=""
      />
      <Typography component="h3" className={classes.title}>About {props.author}</Typography>
      <ArticleBody className={classes.text} content={props.bio} key={props.author} />
    </div>
  )
}

export default AboutAuthor