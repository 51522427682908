import * as React from "react"
import { graphql } from "gatsby"
import Layout from "components/Layout";
import Seo from 'components/Seo'
import MetaSeo from "components/Seo";
import Article from 'templates/article-template'
import EmailIcon from '@material-ui/icons/MailOutline';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

const buttons = [
  {
    text: "Let's discuss your service or repair needs",
    to: '/contact',
    icon: <EmailIcon />
  },
  {
    text: 'Return to services',
    to: '/services',
    icon: <ArrowBackIosIcon />
  },
]

export default ({ data }) => {
  const {
    type,
    title,
    slug,
    tags,
    metaDescription
  } = data.post.frontmatter;
  const content = data.post.html;
  return (
    <Layout>
      <Seo
        slug={slug}
        title={title}
        description={metaDescription}
        image={data.post.frontmatter.postImage.childImageSharp.original.src}
      />
      <MetaSeo title={title} description={metaDescription} />
      <Article
        tags={tags}
        buttons={buttons}
        title={title}
        image={data.post.frontmatter.postImage.childImageSharp.original.src}
        description={metaDescription}
        content={content}
        desc={metaDescription}
        type={type}
      />
    </Layout>
  );
};

export const query = graphql`
  query($slug: String!) {
    post: markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        metaDescription
        slug
        tags
        postImage {
          childImageSharp {
            original {
              src
            }
            fluid(maxHeight: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;