import * as React from "react"
import EmailForm from 'components/EmailForm'
import CardSection from 'components/CardSection'

function EmailFormArticle() {
    return (
        <CardSection title="Subscribe to get articles delivered via email">
            <EmailForm />
        </CardSection>
    )
}

export default EmailFormArticle