import * as React from "react"
import { Link } from "gatsby"
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    position: 'relative',
    top: '0',
    left: '0',
    width: '100%',
    height: '20rem',
    zIndex: '1',
    padding: '2rem',
    margin: theme.spacing(0, 0, 6, 0),
    [theme.breakpoints.down('sm')]: {
      height: '20rem',
      padding: '1rem',
    },
  },
  content: {
    color: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    height: '100%'
  },
  title: {
    textAlign: 'center',
    fontSize: '2.5rem',
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
    },
  },
  subtitle: {
    textAlign: 'center',
    fontSize: '1.4rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem',
    },
  },
  infoWrapper: {
    textAlign: 'left',
  },
  info: {
    fontSize: '1rem',
    color: theme.palette.background.default,
    textTransform: 'capitalize',

  }
}));

function Hero(props) {
  const classes = useStyles();
  return (
    <div>
      <div
        className={classes.root}
        style={{ backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(" + props.image + ")" }}
      >
        <div className={classes.content}>
          <Typography className={classes.title} component="h1">{props.title}</Typography>
          <Typography className={classes.subtitle} component="h2">{props.description}</Typography>
          <div className={classes.infoWrapper}>
            {props.region && (
              <Typography className={classes.info} component="h3">{props.region}</Typography>
            )}
            {props.author && (
              <Typography className={classes.info} component="h3">{props.category === "projects" ? props.author : 'By ' + props.author}</Typography>
            )}

            {props.date && <Typography className={classes.info} component="h3">Published: {props.date}</Typography>}
            {
              props.updated && <Typography className={classes.info} component="h3">Updated: {props.updated}</Typography>}
            {
              props.category &&
              <Typography className={classes.info} component="h3">
                {'Category: '}
                <Link
                  className={classes.info}
                  to={`/articles/${props.category}`
                  }
                >
                  {props.category}
                </Link></Typography>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hero