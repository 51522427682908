import { Box, IconButton, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  Email, Facebook,
  // Instagram,
  LinkedIn, Twitter
} from '@material-ui/icons';
import CardSection from 'components/CardSection';
import { graphql, StaticQuery } from "gatsby";
import * as React from 'react'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
  button: {
    padding: theme.spacing(1),
    margin: theme.spacing(0,2),
    color: theme.palette.secondary.main,
    fontSize: '3.2rem',
    '&:hover': {
      color: theme.palette.primary.main,
    }
  }
}));

function IconButtonStyled(props) {
  const classes = useStyles();
  return (
    <Tooltip title={'Share via ' + props.name}>
      <IconButton
        className={classes.button}
        href={props.href}
        target="_blank"
        rel="noopener noreferrer"
      >
        <span style={{ display: 'none' }}>{props.name}</span>
        {props.children}
      </IconButton>
    </Tooltip>
  )
}

function Sharebar(props) {
  let { location, title, desc } = props;
  location = encodeURIComponent(location.pathname);
  return (
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              siteUrl
            }
          }
        }
      `}
      render={data => {
        const base = encodeURIComponent(data.site.siteMetadata.siteUrl);
        return (
          <CardSection
            title="Share this article"
          >
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              p={3}
              m={3}
            >
              <IconButtonStyled
                href={`mailto:?&body=${base + location}`}
                name="email"
              >
                <Email />
              </IconButtonStyled>

              <IconButtonStyled
                href={`http://twitter.com/share?text=${title}:%20${desc}&url=${base + location}`}
                name="Twitter"
              >
                <Twitter />
              </IconButtonStyled>

              <IconButtonStyled
                href={`https://www.linkedin.com/shareArticle?mini=true&url=${base + location}&title=${title}&summary=${desc}&source=${base}`}
                name="LinkedIn"
              >
                <LinkedIn />
              </IconButtonStyled>

              <IconButtonStyled
                href={`https://www.facebook.com/sharer/sharer.php?u=${base + location}`}
                name="Facebook"
              >
                <Facebook />
              </IconButtonStyled>
            </Box>
          </CardSection>
        );
      }}
    />
  );
};

export default Sharebar
