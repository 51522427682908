import { makeStyles } from '@material-ui/core/styles'
import AboutAuthor from 'components/AboutAuthor'
import CardSection from 'components/CardSection'
import EmailFormArticle from 'components/EmailFormArticle'
import Hero from "components/Hero.js"
import ArticleBody from 'components/ArticleBody'
import Sharebar from 'components/Sharebar'

import {
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Typography
} from '@material-ui/core'
import React, { Fragment } from 'react'
import CheckBoxOutlined from '@material-ui/icons/CheckBoxOutlined';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(0, 0, 3, 0),
        margin: theme.spacing(0, 0, 3, 0),
    },
    articleWrapper: {
        margin: '35px auto',
        [theme.breakpoints.down('sm')]: {
            width: '95%',
        },
        [theme.breakpoints.up('md')]: {
            width: '80%',
        },
        [theme.breakpoints.up('lg')]: {
            width: '80%',
        },
    }
}));

function Article(props) {
    const classes = useStyles();
    return (
        <Fragment>
            <CardSection buttons={props.buttons} scheme2>
                <div className={classes.articleWrapper}>
                    <Hero
                        author={props.author}
                        date={props.date}
                        updated={props.updated}
                        category={props.category}
                        title={props.title}
                        image={props.image}
                        description={props.description}
                        region={props.region}
                    />
                    {props.tags &&
                        <List dense={true}>
                            {props.tags &&
                                props.tags.map((item, i) => (
                                    <ListItem >
                                        <ListItemIcon>
                                            <CheckBoxOutlined color="primary" />
                                        </ListItemIcon>
                                        <ListItemText
                                            disableTypography
                                            primary={<Typography component="h2" variant="h6" color="primary">{item}</Typography>}
                                        />
                                    </ListItem>
                                ))}
                        </List>
                    }
                    <ArticleBody key={props.title} content={props.content} />
                    {props.author &&
                        <AboutAuthor
                            author={props.author}
                            image={props.authorImage}
                            bio={props.bio}
                        />
                    }
                </div>
            </CardSection>
            {props.sharebar &&
                <Sharebar
                    desc={props.metaDescription}
                    location={props.location}
                    title={props.title}
                />
            }
            {props.email &&
                <EmailFormArticle />
            }
        </Fragment>
    )
}

export default Article