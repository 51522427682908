import React, { Fragment } from "react"
import { StaticQuery, graphql } from "gatsby"
import { makeStyles } from '@material-ui/core/styles';
import {
  TextField,
  Button,
  Grid
} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    margin: theme.spacing(5, 0)
  },
}));

function EmailForm(props) {
  const classes = useStyles();
  return (
    <Fragment>
      <StaticQuery
        query={graphql`
        query {
          site {
            siteMetadata {
              mailChimpUrl
              mailChimpToken
            }
          }
        }
      `}
        render={data => (
          <form
            className={classes.root}
            action={data.site.siteMetadata.mailChimpUrl}
            method="POST">
            <Grid container justify="center" spacing={2}>
              <Grid item xs={12} md={9}>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  required
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  helperText="Articles only, never for spam"
                />
              </Grid>
              <Grid item>
                <Button
                  fullWidth
                  type="submit"
                  color="primary"
                >
                  Sign Up
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      />
    </Fragment>
  )
}

export default EmailForm